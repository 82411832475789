import { uuid } from "short-uuid";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useDialog } from "./dialog";

import type { Contact } from "API";
import useDatalist from "hooks/datalist";
import { useAuth } from "contexts/auth";
import { useAlerts } from "contexts/alerts";
import { useDebounce } from "hooks/debounce";
import { download, parseImportTemplate } from "utils/masterData/template";

interface ContactsContextValue {
  loading: boolean;
  contacts: Contact[];
  searchedContacts: Contact[];
  setContactSearchText: (text: string) => void;
  loadNext: () => void;
  refetch: (variables: {
    accountId?: string;
    tenantId?: string;
  }) => Promise<void>;
  create: (variables: any) => Promise<void>;
  update: (variables: any) => Promise<void>;
  remove: (ids: string[]) => Promise<void>;
  downloadImportTemplate: () => Promise<void>;
  importTemplates: () => Promise<void>;
}

interface ContactsContextProps {
  children: ReactNode;
  variables?: { [key: string]: any };
  by?: "AccountId" | "TenantId";
}

const ContactsContext = createContext<ContactsContextValue>({
  contacts: [],
  searchedContacts: [],
  setContactSearchText: () => null,
  loading: false,
  loadNext: () => null,
  refetch: () => Promise.resolve(),
  create: () => Promise.resolve(),
  update: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  downloadImportTemplate: () => Promise.resolve(),
  importTemplates: () => Promise.resolve(),
});

export const ContactsProvider = ({
  children,
  variables,
  by,
}: ContactsContextProps) => {
  const { user } = useAuth();
  const { open } = useDialog();
  const { addAlert } = useAlerts();
  const {
    data,
    nextToken,
    loading,
    loadNext,
    refetch,
    create,
    update,
    remove,
    appendNext,
  } = useDatalist({
    query: `contactsBy${by || "TenantId"}`,
    variables:
      by === "AccountId"
        ? variables
        : {
            tenantId: user?.tenantId,
          },
  });
  // 最初は何も検索結果欲しくないので空白にしておく
  const [contactSearchText, setContactSearchText] = useState("       ");
  const debouncedInputText = useDebounce(contactSearchText, 500);
  const {
    data: searchedContacts,
    refetch: refetchSearchedContacts,
    appendNext: appendSearchedContacts,
    nextToken: nextSearchedContactsToken,
  } = useDatalist({
    query: "contactsByTenantId",
    variables: {
      limit: 1000,
      tenantId: user?.tenantId,
      filter: {
        name: {
          or: [
            {
              name: {
                contains: contactSearchText,
              },
            },
            {
              firstName: {
                contains: contactSearchText,
              },
            },
            {
              lastName: {
                contains: contactSearchText,
              },
            },
          ],
        },
      },
    },
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNextTenantContacts, setFetchingNextTenantContacts] =
    useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextSearchedContactsToken && !fetchingNextTenantContacts) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNextTenantContacts(true); // フェッチ中の状態をtrueに設定
      appendSearchedContacts().finally(() =>
        setFetchingNextTenantContacts(false)
      ); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSearchedContactsToken]);

  // 検索文字列の更新
  const doSetContactSearchText = (text: string) => {
    // textが空白のみの場合はスキップ
    if (text.trim().length === 0) return;
    setContactSearchText(text);
  };

  // 検索文字列が変わったらrefetchする
  useEffect(() => {
    refetchSearchedContacts({
      limit: 1000,
      tenantId: user?.tenantId,
      filter: {
        or: [
          {
            name: {
              contains: contactSearchText,
            },
          },
          {
            firstName: {
              contains: contactSearchText,
            },
          },
          {
            lastName: {
              contains: contactSearchText,
            },
          },
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputText]);

  const createContact = async (variables: any) => {
    await create("createContact", {
      tenantId: user?.tenantId,
      ...variables,
    });
    addAlert({ message: "取引先責任者を作成しました", severity: "success" });
  };

  const updateContact = async (variables: any) => {
    await update("updateContact", {
      tenantId: user?.tenantId,
      ...variables,
    });
    addAlert({ message: "取引先責任者を更新しました", severity: "success" });
  };

  const removeContact = async (ids: string[]) => {
    open({
      title: "取引先責任者を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        const promises = ids.map((id) =>
          remove("deleteContact", {
            id,
          })
        );
        await Promise.all(promises);

        addAlert({
          message: "取引先責任者を削除しました",
          severity: "success",
        });
      },
    });
  };
  // 取引先担当者情報import用templateのダウンロード状態用
  const [error, setError] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const [exportingTemplates, setExportingTemplates] = useState<string[]>([]);
  const downloadImportTemplate = async () => {
    setError(false);
    const exportId = uuid();
    setExporting(true);
    setExportingTemplates([...exportingTemplates, exportId]);
    try {
      // データベースから取引先担当者情報を取得
      const contacts = data || [];
      // 取引先担当者情報をテンプレートの形式に変換
      const templateData = contacts.map((contact: Contact) => {
        const {
          id,
          lastName,
          firstName,
          account,
          department,
          email,
          phone,
          mobilePhone,
          contactId,
        } = contact;
        return {
          "取引先責任者id(編集不可)": id,
          "姓(必須)": lastName,
          名: firstName,
          "取引先id(必須)": account?.id, // accountフィールドのidフィールドを使用
          "取引先名(参照)": account?.name,
          部署: department,
          Eメール: email,
          電話番号: phone,
          携帯電話: mobilePhone,
          取引先担当者外部連携id: contactId,
        };
      });
      // テンプレートをダウンロード
      await download(templateData, "contacts");
    } catch (err: any) {
      setError(true);
      if (typeof err === "string") {
        addAlert({
          message: `取引先担当者読込用テンプレートが出力されませんでした: ${err}`,
          severity: "error",
        });
      } else if (err instanceof Error) {
        addAlert({
          message: `取引先担当者読込用テンプレートが出力されませんでした: ${err.message}`,
          severity: "error",
        });
      } else {
        addAlert({
          message: `取引先担当者読込用テンプレートが出力されませんでした`,
          severity: "error",
        });
      }
    } finally {
      setExportingTemplates(exportingTemplates.filter((e) => e !== exportId));
    }
  };
  // templateのimport状態用
  const [importError, setImportError] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [importingTemplates, setImportingTemplates] = useState<string[]>([]);
  const importTemplates = async () => {
    // インポートエラーフラグをリセット
    setImportError(false);
    // インポートIDを生成
    const importId = uuid();
    // インポート中フラグを設定
    setImporting(true);
    // インポート中のテンプレートIDを追加
    setImportingTemplates([...importingTemplates, importId]);

    // ファイル選択用のinput要素を作成
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.multiple = true;
    input.onchange = async (event: Event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files) {
        // 選択されたファイルを順次処理
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          // ファイルの拡張子が.xlsxの場合のみ処理
          if (file && file.name.endsWith(".xlsx")) {
            try {
              // Excelファイルをパースし、GraphQLの入力形式に変換
              const importedInput = await parseImportTemplate(file, "contacts");
              // 必須列のチェック
              const missingValues = importedInput.filter(
                (row) => !row["lastName"] || !row["accountId"]
              );
              if (missingValues.length > 0) {
                throw new Error(
                  "すべての行に対して、姓と取引先idに値が入っている必要があります。"
                );
              }
              // 変換された入力データを使用して取引先担当者を作成または更新
              for (let j = 0; j < importedInput.length; j++) {
                const { id, ...rest } = importedInput[j];
                const existingContact = data?.find(
                  (contact: Contact) => contact.id === id
                );
                if (existingContact) {
                  // 同じidの取引先担当者が既に存在する場合はupdate
                  await update("updateContact", {
                    id,
                    ...rest,
                    name: `${rest.lastName ?? ""} ${rest.firstName ?? ""}`, // PAX-649 CSVインポート時nameフィールドも設定
                    tenantId: user?.tenantId,
                  });
                } else {
                  // idがnullの場合は新しいidを生成してcreate
                  const newId = uuid();
                  await create("createContact", {
                    id: newId,
                    ...rest,
                    name: `${rest.lastName ?? ""} ${rest.firstName ?? ""}`, // PAX-649 CSVインポート時nameフィールドも設定
                    tenantId: user?.tenantId,
                  });
                }
              }
            } catch (err: any) {
              // エラー処理
              setImportError(true);
              if (typeof err === "string") {
                addAlert({
                  message: `取引先担当者読込用テンプレートがインポートできませんでした: ${err}`,
                  severity: "error",
                });
              } else if (err instanceof Error) {
                addAlert({
                  message: `取引先担当者読込用テンプレートがインポートできませんでした: ${err.message}`,
                  severity: "error",
                });
              } else {
                addAlert({
                  message: `取引先担当者読込用テンプレートがインポートできませんでした`,
                  severity: "error",
                });
              }
            }
          } else {
            // ファイルの拡張子が.xlsx以外の場合はエラー
            setImportError(true);
            addAlert({
              message: `取引先担当者読込用テンプレートがインポートできませんでした`,
              severity: "error",
            });
          }
        }
      }
      // インポート中のテンプレートIDを削除
      setImportingTemplates(importingTemplates.filter((e) => e !== importId));
      // インポート中フラグをリセット
      setImporting(false);
    };
    // ファイル選択ダイアログを表示
    input.click();
  };

  useEffect(() => {
    if (!variables?.accountId) return;
    refetch(variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return (
    <ContactsContext.Provider
      value={{
        searchedContacts: searchedContacts,
        setContactSearchText: doSetContactSearchText,
        contacts: data || [],
        loading,
        loadNext,
        refetch: async (variables) => await refetch(variables),
        create: createContact,
        update: updateContact,
        remove: removeContact,
        downloadImportTemplate,
        importTemplates,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export const useContacts = () => {
  const contactsContext = useContext(ContactsContext);

  if (contactsContext === undefined) {
    throw new Error("useContacts must be within AuthProvider");
  }

  return contactsContext;
};
